/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #27096d !important;
  color: #fbf9ff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.brand-name {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 700;
  /* This makes the font bold */
  /* Additional styling can go here */
  letter-spacing: 2.5px;
  /* This adds 50 pixels of spacing between each letter */

}

.brand-name .brand-x {
  display: inline-block;
  /* This allows the rotation to happen on the inline element */
  transition: transform 0.6s ease-in-out;
  /* Additional styling for the 'X' can go here */
}

.brand-name .brand-x:hover {
  transform: rotate(360deg);
}


@media (max-width: 768px) {
  .Banner{
    min-height: 100vh;
  }
  .App>* {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .App>.exclude-fullscreen {
    min-height: auto;
  }
}

.Services {
  border: #fbf9ff 1px solid;
}