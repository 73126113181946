.footer {
  background-color: #0d0325;
  color: #fbf9ff;
  padding-top: 20px; 
}

.footer .brand-name {
  color: #ffffff; 
  font-weight: bold; 
}

.footer .brand-name span {
  color: #ffffff; 
}

.footer .footer-nav {
  text-align: right;
}

.footer .footer-nav a {
  color: #fbf9ff; 
  margin: 0 15px; 
  font-size: 1rem; 
  transition: color 0.3s; 
  text-decoration: none;
}

.footer .footer-nav a:hover {
  color: #a183e7; 
  text-decoration: none; 
}

@media (max-width: 768px) {
  .footer .footer-nav {
      text-align: center;
  }

  .footer .footer-nav a {
      display: inline-block; 
      margin: 5px 10px;
  }

  .footer .brand-name, .footer p {
      text-align: center; 
  }
}

.footer p {
  font-size: 0.875rem; 
  margin-top: 20px;
}
