.img-fluid {
    max-width: 100%;
    height: auto;
}

.about-section {
    position: relative;
    padding: 5rem 0;
    background: #fbf9ff;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.text-part h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #0d0325;
    text-align: center;
    position: relative;
}

.text-part h1:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 50%;
    height: 4px;
    background: #a183e7;
    border-radius: 2px;
}

.text-part .highlight {
    color: #5e60ce;
}

.text-part .description {
    font-size: 1.25rem;
    color: #0d0325;
    line-height: 1.75;
    margin-bottom: 3rem;
}

.nav-pills .nav-link {
    color: #0d0325;
    background-color: transparent;
    margin: 0 5px;
    transition: all 0.3s ease;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover {
    color: #000000;
    background-color: #a183e7;
}

/* Responsive Media queries */
@media (max-width: 768px) {
    .text-part h1 {
        font-size: 2.5rem;
    }

    .text-part .description {
        font-size: 1.15rem;
    }

    .nav-pills .nav-link {
        padding: .5rem 1rem;
        font-size: .9rem;
    }
}

@media (max-width: 576px) {
    .text-part h1 {
        font-size: 2rem;
    }

    .text-part .description,
    .nav-pills .nav-link {
        font-size: 1rem;
    }
}

/* Existing styles... */
.message-style span{
    color: #5e60ce;
}
.message-style {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .mobile-tab-content {
    padding: 0;
  }
  
  @media (max-width: 768px) {
    .nav-pills {
      display: none;
    }
  }
  